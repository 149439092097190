
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

import moment from "moment";
moment.locale("id")

const toast = useToast();

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Loading,
    Field,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // Loading overlay
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      tableData: <any>[],
      listSekolah: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      listMetode: [
        {
          id: 'MANUAL',
          name: 'MANUAL',
        },
      ],
      metodePembayaran: '',
      datepembayaran: "",
      name: "",
      schoolId: "",
      schoolName: "",
      dateFrom: "",
      dateUntil: "",
      role: "",
      totalInvoice: "",
      amount: 0,
      discount: 0,
      tax: 0,
      shipping: 0,
      totalAmount: 0,
      totalPaid: "",
      minJtp: "",
      totalUnPaid: "",

      detailShow: {
        noRef: "",
        penanggungJawab: "",
        executor: "",
        bankNameSender: "",
        imageReceipt: "",
        imageShow: '/media/no-image/No_Image_Vertikal_TKI.png',
        noTagihan: "",
      },

      showFilter : false,
      timer: 0,
    };
  },

  beforeMount() {
    const store = useStore();
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;

    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.getListSekolah();
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.schoolName = JSON.parse(localStorage.getItem("schoolName")!);
    // var schoolID = localStorage.getItem("sekolahIdMenuInvoice")!;
    // if (schoolID) {
    //   this.schoolId = schoolID;
    // }
    this.getResume()
    this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("History Pembayaran", ["Data"]);
    this.dateFrom = this.getDateNow();
    this.dateUntil = this.getDateNow();
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      const toast = useToast();

      let schoolId = "";
      if (this.schoolId) {
        schoolId = "schoolId=" + this.schoolId + "&";
      }

      let dateFrom = "";
      if (this.dateFrom) {
        dateFrom = this.date(this.dateFrom);
      } else {
        dateFrom = this.getDateNow();
      }

      let dateUntil = "";
      if (this.dateUntil) {
        dateUntil = this.date(this.dateUntil);
      } else {
        dateUntil = this.getDateNow();
      }

      let office = ""
      if (this.role != 'ADMIN_SCHOOL') {
        office = 'office/'
      } else {
        office = ''
      }

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/" + office + "history_payment/all?" +
        schoolId +
        "page=" +
        page +
        "&paymentMethod=" +
        this.metodePembayaran +
        "&dateFrom=" +
        dateFrom +
        "&dateUntil=" +
        dateUntil +
        "&size=" +
        size +
        "&sort=paymentDate&dir=-1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
        }
      );
    },

    getResume() {
      let schoolId = "";
      if (this.schoolId) {
        schoolId = "schoolId=" + this.schoolId + "&";
      } else {
        schoolId;
      }
      let dateFrom = "";
      if (this.dateFrom) {
        dateFrom = this.date(this.dateFrom);
      } else {
        dateFrom = this.getDateNow();
      }

      let dateUntil = "";
      if (this.dateUntil) {
        dateUntil = this.date(this.dateUntil);
      } else {
        dateUntil = this.getDateNow();
      }

      console.log(this.schoolId, "uu")
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/invoice/finance/resume?" + schoolId + "&paymentMethod=" + this.metodePembayaran
        + "&dateFrom=" + dateFrom + "&dateUntil=" + dateUntil
      )
        .then(({ data }) => {
          this.totalInvoice = data.totalInvoice;
          this.totalPaid = data.totalPaid;
          this.totalUnPaid = data.totalUnPaid;
          this.minJtp = data.minJtp;
        })
        .catch(({ response }) => { });
    },

    pilihSekolah(event) {
      // console.log(event)
      // this.schoolId = event;
      var namaSekolah = "";
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
        this.schoolName = school.name;
        this.getData(this.paging.size, this.paging.page);
        this.getResume();
      } else {
        this.schoolId = "";
        this.getData(this.paging.size, this.paging.page);
        // this.totalInvoice = '';
        // this.totalPaid = '';
        // this.totalUnPaid = '';
        // this.minJtp = '';

        this.getResume();
      }

      
      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
    },
    clickReset() {
      this.detailShow = {
        noRef: "",
        penanggungJawab: "",
        executor: "",
        bankNameSender: "",
        imageReceipt: "",
        imageShow: '/media/no-image/No_Image_Vertikal_TKI.png',
        noTagihan: "",
      }
    },
    pilihTanggal() {
      this.getData(this.paging.size, this.paging.page);
    },

    pilihTanggalAwal() {
      this.getData(this.paging.size, this.paging.page);
      this.getResume()
    },
    pilihTanggalAkhir() {
      this.getData(this.paging.size, this.paging.page);
      this.getResume()
    },

    onChangeSekolah() {
      this.getData(this.paging.size, this.paging.page);
    },
    changeManual() {
      this.metodePembayaran = "MANUAL"
      this.getData(this.paging.size, this.paging.page);
      this.getResume()
    },
    changeOtomatis() {
      this.metodePembayaran = "AUTOMATIC"
      this.getData(this.paging.size, this.paging.page);
      this.getResume()
    },
    changeAll() {
      this.metodePembayaran = ""
      this.getData(this.paging.size, this.paging.page);
      this.getResume()
    },

    detailItem(item) {
      this.detailShow.noRef = item.refNumber;
      this.detailShow.noTagihan = item.number;
      this.detailShow.penanggungJawab = item.pic;
      this.detailShow.executor = item.executor;
      this.detailShow.bankNameSender = item.bankNameSender;
      if (item.imageReceipt[0]) {
        // "data:image/jpg;base64,"+val1
        ApiService.getWithoutSlug(
          "crmv2/main_image/image/get/base64/" + item.imageReceipt[0]
        )
          .then((res) => {
            this.detailShow.imageShow = "data:image/jpg;base64," + res.data;
            // this.urlimage1 = URL.createObjectURL(res.data);
          })
          .catch((e) => {
            this.detailShow.imageShow = "/media/no-image/No_Image_Horizontal_TKI.png";
            toast.error(e.res.data.detail);
            this.isLoading = false;
          });
      }
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      console.log(page)
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate: function (date) {
      // return moment(date, "D-MM-YYYY HH:mm").format("D-MM-YYYY HH:mm");
      return moment(date).format("YYYY-MM-DD HH:mm");
    },

    date(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },

    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = Date.now();
      link.href = url;
      link.setAttribute("download", "Report_invoice" + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    download() {
      this.isLoading = true;

      const toast = useToast();

      let schoolId = "";
      if (this.schoolId) {
        schoolId = "schoolId=" + this.schoolId + "&";
      }

      let dateFrom = "";
      if (this.dateFrom) {
        dateFrom = this.date(this.dateFrom);
      } else {
        dateFrom = this.getDateNow();
      }

      let dateUntil = "";
      if (this.dateUntil) {
        dateUntil = this.date(this.dateUntil);
      } else {
        dateUntil = this.getDateNow();
      }

      let office = ""
      if (this.role != 'ADMIN_SCHOOL') {
        office = 'office/'
      } else {
        office = ''
      }
      // if (this.schoolId) {
        this.isLoading = true;
        ApiService.getDownload(
          `crmv2/main_invoice/invoice/${office}history_payment/download?${schoolId}&paymentMethod=${this.metodePembayaran}&dateFrom=${dateFrom}&dateUntil=${dateUntil}`
        )
          .then((response) => {
            this.handleBlobResponse(response.data, "xlsx");
            toast.success("Sukses Unduh File");
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error("Gagal Unduh File");
            this.isLoading = false;
          });
      // } else {
      //   this.isLoading = false
      //   toast.error("Pilih Sekolah Dahulu");
      // }
    },

    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
          const page = this.paging.page;
          this.getData(this.paging.size, page);
        }, 1000)
    },
    showHideFilter(){
      this.showFilter = !this.showFilter;
    },
  },
});
